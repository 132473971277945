@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom fonts */
@font-face {
	font-family: "youngone";
	src: url("../src/assets/fonts/YoungonesRsRegular-4lVx.ttf");
}

@font-face {
	font-family: "hotmustard";
	src: url("../src/assets/fonts/Hot_20Mustard_20BTN.ttf");
}

* {
	user-select: none;
}

body > #root {
	height: 100vh;
	width: 100vw;
	display: grid;
	place-items: center;
	background-color: rgb(248 250 252);
	background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./assets/images/titlescreen.webp");
	background-position: center;
	object-fit: cover;
	overflow: hidden;
	/* Toastify */

	--toastify-icon-color-success: #e879f9;
	--toastify-text-color-light: rgb(31, 31, 31);
	--toastify-color-progress-success: #e879f9;
}

body {
	overflow: hidden;
}

.fullscreen-enabled {
	background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./assets/images/titlescreen.webp");
	background-position: center;
	object-fit: cover;
}

@media screen and (orientation: portrait) {
	body > #root {
		transform: rotate(-90deg);
		transform-origin: left top;
		width: 100vh;
		height: 100vw;
		overflow-x: hidden;
		position: absolute;
		top: 100%;
		left: 0;
	}
}

/* Chatbox border */
.borderimg {
	border: 15px solid #fb7185;
	border-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75'%3E%3Cg fill='none' stroke='%23FB7185' stroke-width='2'%3E%3Cpath d='M1 1h73v73H1z'/%3E%3Cpath d='M8 8h59v59H8z'/%3E%3Cpath d='M8 8h16v16H8zM51 8h16v16H51zM51 51h16v16H51zM8 51h16v16H8z'/%3E%3C/g%3E%3Cg fill='%23FB7185'%3E%3Ccircle cx='16' cy='16' r='2'/%3E%3Ccircle cx='59' cy='16' r='2'/%3E%3Ccircle cx='59' cy='59' r='2'/%3E%3Ccircle cx='16' cy='59' r='2'/%3E%3C/g%3E%3C/svg%3E")
		25;
}

.borderimg2 {
	border: 10px solid #fb7185;
	border-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75'%3E%3Cg fill='none' stroke='%23FB7185' stroke-width='2'%3E%3Cpath d='M1 1h73v73H1z'/%3E%3Cpath d='M8 8h59v59H8z'/%3E%3Cpath d='M8 8h16v16H8zM51 8h16v16H51zM51 51h16v16H51zM8 51h16v16H8z'/%3E%3C/g%3E%3Cg fill='%23FB7185'%3E%3Ccircle cx='16' cy='16' r='2'/%3E%3Ccircle cx='59' cy='16' r='2'/%3E%3Ccircle cx='59' cy='59' r='2'/%3E%3Ccircle cx='16' cy='59' r='2'/%3E%3C/g%3E%3C/svg%3E")
		25;
}

/* Text outline in title */
.outline-title {
	text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

/* Datalist */
.react-datalist-input__container > * {
	box-sizing: border-box;
}
.react-datalist-input__container {
	width: 100%;
	position: relative;
	--shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	--gray: #c8c8c8;
	--padding: 5px;
	--border-radius: 5px;
	--border: 1px solid var(--gray);
}
.react-datalist-input__textbox {
	text-align: center;
	width: 100%;
	font-size: 1vw;
	line-height: 1.25rem;
	font-weight: 900;
	margin: 0;
	padding: var(--padding);
	/* border: var(--border); */
	border-radius: var(--border-radius);
}
.react-datalist-input__listbox {
	font-weight: 900;
	width: 100%;
	position: absolute;
	margin: 2px 0 0 0;
	padding: 0;
	/* border: var(--border); */
	border-radius: var(--border-radius);
	background-color: white;
	display: flex;
	flex-direction: column;
	list-style-type: none;
	box-shadow: var(--shadow);
	z-index: 10;
	max-height: 30vh;
	overflow-y: auto;
}
.react-datalist-input__listbox-option {
	width: 100%;
	cursor: pointer;
	margin: 0;
	padding: var(--padding);
}
.react-datalist-input__listbox-option:focus {
	background-color: #f3ddf7;
	border-radius: var(--border-radius);
}
.react-datalist-input__listbox-option:hover {
	background-color: #f3ddf7;
	border-radius: var(--border-radius);
}

/* Loading screen */
.loader {
	position: absolute;
	width: 40px;
	height: 40px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.loader .center {
	width: 40px;
	height: 40px;
	background: #d946ef;
	border-radius: 50%;
	animation: center 3.2s ease-in-out infinite;
}
.loader .item {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 15px;
	left: 0;
	right: 0;
	margin: auto;
	background: #d946ef;
	border-radius: 50%;
}

.item-1 {
	animation: anim-1 3.2s ease-in-out infinite 0.2s;
	animation-fill-mode: backwards;
}

@keyframes anim-1 {
	0%,
	60%,
	100% {
		transform: rotate(45deg) translateX(40px) scale(1);
	}
	10%,
	50% {
		transform: rotate(45deg) translateX(0) scale(1.5);
	}
}

.item-2 {
	animation: anim-2 3.2s ease-in-out infinite 0.4s;
	animation-fill-mode: backwards;
}

@keyframes anim-2 {
	0%,
	60%,
	100% {
		transform: rotate(90deg) translateX(40px) scale(1);
	}
	10%,
	50% {
		transform: rotate(90deg) translateX(0) scale(1.5);
	}
}

.item-3 {
	animation: anim-3 3.2s ease-in-out infinite 0.6s;
	animation-fill-mode: backwards;
}

@keyframes anim-3 {
	0%,
	60%,
	100% {
		transform: rotate(135deg) translateX(40px) scale(1);
	}
	10%,
	50% {
		transform: rotate(135deg) translateX(0) scale(1.5);
	}
}

.item-4 {
	animation: anim-4 3.2s ease-in-out infinite 0.8s;
	animation-fill-mode: backwards;
}

@keyframes anim-4 {
	0%,
	60%,
	100% {
		transform: rotate(180deg) translateX(40px) scale(1);
	}
	10%,
	50% {
		transform: rotate(180deg) translateX(0) scale(1.5);
	}
}

.item-5 {
	animation: anim-5 3.2s ease-in-out infinite 1s;
	animation-fill-mode: backwards;
}

@keyframes anim-5 {
	0%,
	60%,
	100% {
		transform: rotate(225deg) translateX(40px) scale(1);
	}
	10%,
	50% {
		transform: rotate(225deg) translateX(0) scale(1.5);
	}
}

.item-6 {
	animation: anim-6 3.2s ease-in-out infinite 1.2s;
	animation-fill-mode: backwards;
}

@keyframes anim-6 {
	0%,
	60%,
	100% {
		transform: rotate(270deg) translateX(40px) scale(1);
	}
	10%,
	50% {
		transform: rotate(270deg) translateX(0) scale(1.5);
	}
}

.item-7 {
	animation: anim-7 3.2s ease-in-out infinite 1.4s;
	animation-fill-mode: backwards;
}

@keyframes anim-7 {
	0%,
	60%,
	100% {
		transform: rotate(315deg) translateX(40px) scale(1);
	}
	10%,
	50% {
		transform: rotate(315deg) translateX(0) scale(1.5);
	}
}

.item-8 {
	animation: anim-8 3.2s ease-in-out infinite 1.6s;
	animation-fill-mode: backwards;
}

@keyframes anim-8 {
	0%,
	60%,
	100% {
		transform: rotate(360deg) translateX(40px) scale(1);
	}
	10%,
	50% {
		transform: rotate(360deg) translateX(0) scale(1.5);
	}
}

@keyframes center {
	0%,
	10%,
	90%,
	100% {
		transform: scale(0.7);
	}
	45%,
	55% {
		transform: scale(1);
	}
}
